import Waiting from "../../component/waiting/index"


const Home=()=>{
    return (
        <div>
            <Waiting/>
        </div>
    )
}

export default Home


















