import "./index.css"


const App=()=>{
    return(
        <div>

            <h1>It will be opened</h1>
            <h1>on 1th March</h1>

            <span>Enjoy Spring Festival</span>

        </div>
    )
}

export default App