import {createBrowserRouter} from "react-router-dom";
import Home from "../page/home/index";
import Game from '../page/game/index'

const Router=createBrowserRouter([{
    path:"/",
    element:<Home/>

},
    {path:"/game",
    element:<Game></Game>}
])

export default Router