

const App=()=>{
    return(
        <div>
            I'm game page
        </div>
    )
}

export default App